<template>
  <v-main style="background-color:#bee3f5;">
    <v-container >
        <v-card
            class="bl_contactCard"
            elevation="5"
            max-width="600"
            style="margin-top:50px; margin-bottom:50px; padding: 32px 16px;"
        >
            <v-card-title>お問い合わせ</v-card-title>
            <v-card-text>
              <p>お問い合わせありがとうございます。確認し返信いたします。</p>
              <p>
                  返信が遅い場合は届いていない可能性がございます。お手数ですが、info[at]interemit.com までご連絡いただければ幸いです。
              </p>
            </v-card-text>
        </v-card>
    </v-container>
  </v-main>
</template>

<script>

  export default {
    name: 'contactThanks',
    components: {

    },
    data(){
      return{
      }
    }
  }
</script>

<style scoped>
</style>
